import { Iroute } from './interface';

/**
 * @ABS === ACCOUNT
 */
export const ACCOUNT: Iroute = {
  path: 'account',
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const LOGIN: Iroute = {
  path: 'login',
  get fullUrl(): string {
    return `${ACCOUNT.fullUrl}/${this.path}`;
  },
};
export const FORGOT_PASSWORD: Iroute = {
  path: 'forgot-password',
  get fullUrl(): string {
    return `${ACCOUNT.fullUrl}/${this.path}`;
  },
};
export const FORGOT_PASSWORD_SUCCESS: Iroute = {
  path: 'forgot-password-successfull',
  get fullUrl(): string {
    return `${ACCOUNT.fullUrl}/${this.path}`;
  },
};
export const RESET_PASSWORD: Iroute = {
  path: 'reset-password',
  get fullUrl(): string {
    return `${ACCOUNT.fullUrl}/${this.path}`;
  },
};

export const RESET_PASSWORD_SUCCESSFULL: Iroute = {
  path: 'reset-success',
  get fullUrl(): string {
    return `${ACCOUNT.fullUrl}/${this.path}`;
  },
};

export const VERIFY_OTP: Iroute = {
  path: 'verify-otp',
  get fullUrl(): string {
    return `${ACCOUNT.fullUrl}/${this.path}`;
  },
};

/**
 * @ABS === LAYOUT
 */
export const LAYOUT: Iroute = {
  path: 'layout',
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

/**
 * @ABS === DASHBOARD
 */
export const DASHBOARD: Iroute = {
  path: 'dashboard',
  get fullUrl(): string {
    return `/${this.path}`;
  },
};
export const USER_MANAGEMENT: Iroute = {
  path: 'user-management',
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

/**
 * @ABS === NOTIFICATION
 */
export const NOTIFICATION: Iroute = {
  path: 'notification',
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const ADD_NOTIFICATION: Iroute = {
  path: 'add-notification',
  get fullUrl(): string {
    return `${NOTIFICATION.fullUrl}/${this.path}`;
  },
};

/**
 * @HELP_AND_SUPPORT
 */
export const HELP_AND_SUPPORT: Iroute = {
  path: 'help-and-support',
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

/**
 * @ABS === HOTEl MANAGEMNT
 */
export const HOTEL_MANAGEMENT: Iroute = {
  path: 'hotel-management',
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const HOTEL_MANAGEMENT_HOTEL_DETAIL: Iroute = {
  path: 'detail',
  get fullUrl(): string {
    return `${HOTEL_MANAGEMENT.fullUrl}/${this.path}`;
  },
};


/**
 * @ABS === MENU MANAGEMENT
 */
export const MENU_MANAGEMENT: Iroute = {
  path: 'menu-management',
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const MENU_MANAGEMENT_ADD_MENU: Iroute = {
  path: 'add-menu',
  get fullUrl(): string {
    return `${MENU_MANAGEMENT.fullUrl}/${this.path}`;
  },
};

export const MENU_MANAGEMENT_EDIT_MENU: Iroute = {
  path: 'edit-menu',
  get fullUrl(): string {
    return `${MENU_MANAGEMENT.fullUrl}/${this.path}`;
  },
};

/**
 * @ABS === EVENT MANAGEMENT
 */
export const EVENT_MANAGEMENT: Iroute = {
  path: 'event-management',
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const EVENT_MANAGEMENT_ADD_EVENT: Iroute = {
  path: 'add-event',
  get fullUrl(): string {
    return `${EVENT_MANAGEMENT.fullUrl}/${this.path}`;
  },
};
export const EVENT_MANAGEMENT_VIEW_EVENT: Iroute = {
  path: 'view-event',
  get fullUrl(): string {
    return `${EVENT_MANAGEMENT.fullUrl}/${this.path}`;
  },
};
export const EVENT_MANAGEMENT_EDIT_EVENT: Iroute = {
  path: 'edit-event',
  get fullUrl(): string {
    return `${EVENT_MANAGEMENT.fullUrl}/${this.path}`;
  },
};


/**
 * @ABS === STATIC_CONTENT
 */
export const STATIC_CONTENT: Iroute = {
  path: 'static-content',
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const STATIC_CONTENT_PUBLIC: Iroute = {
  path: 'static-content-public',
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const PRIVACY_POLICY: Iroute = {
  path: 'privacy-policy',
  get fullUrl(): string {
    return `${STATIC_CONTENT.fullUrl}/${this.path}`;
  },
};

export const PRIVACY_POLICY_PUBLIC: Iroute = {
  path: 'privacy-policy',
  get fullUrl(): string {
    return `${STATIC_CONTENT_PUBLIC.fullUrl}/${this.path}`;
  },
};

export const TERMS_CONDITIONS: Iroute = {
  path: 'terms-condition',
  get fullUrl(): string {
    return `${STATIC_CONTENT.fullUrl}/${this.path}`;
  },
};
export const TERMS_CONDITIONS_PUBLIC: Iroute = {
  path: 'terms-condition',
  get fullUrl(): string {
    return `${STATIC_CONTENT_PUBLIC.fullUrl}/${this.path}`;
  },
};
export const COMMUNITY_GUIDELINES: Iroute = {
  path: 'community-guidelines',
  get fullUrl(): string {
    return `${STATIC_CONTENT.fullUrl}/${this.path}`;
  },
};
export const COMMUNITY_GUIDELINES_PUBLIC: Iroute = {
  path: 'community-guidelines',
  get fullUrl(): string {
    return `${STATIC_CONTENT_PUBLIC.fullUrl}/${this.path}`;
  },
};
export const DATA_POLICY: Iroute = {
  path: 'data-policy',
  get fullUrl(): string {
    return `${STATIC_CONTENT.fullUrl}/${this.path}`;
  },
};
export const DATA_POLICY_PUBLIC: Iroute = {
  path: 'data-policy',
  get fullUrl(): string {
    return `${STATIC_CONTENT_PUBLIC.fullUrl}/${this.path}`;
  },
};
export const COPYRIGHT_POLICY: Iroute = {
  path: 'copyright-policy',
  get fullUrl(): string {
    return `${STATIC_CONTENT.fullUrl}/${this.path}`;
  },
};
export const COPYRIGHT_POLICY_PUBLIC: Iroute = {
  path: 'copyright-policy',
  get fullUrl(): string {
    return `${STATIC_CONTENT_PUBLIC.fullUrl}/${this.path}`;
  },
};
export const FAQS: Iroute = {
  path: 'faqs',
  get fullUrl(): string {
    return `${STATIC_CONTENT.fullUrl}/${this.path}`;
  },
};
export const FAQS_PUBLIC: Iroute = {
  path: 'faqs',
  get fullUrl(): string {
    return `${STATIC_CONTENT_PUBLIC.fullUrl}/${this.path}`;
  },
};

export const ABOUT_US: Iroute = {
  path: 'about-us',
  get fullUrl(): string {
    return `${STATIC_CONTENT.fullUrl}/${this.path}`;
  },
};
export const RETURN_POLICY: Iroute = {
  path: 'return-policy',
  get fullUrl(): string {
    return `${STATIC_CONTENT.fullUrl}/${this.path}`;
  },
};
export const OTHERS: Iroute = {
  path: 'others',
  get fullUrl(): string {
    return `${STATIC_CONTENT.fullUrl}/${this.path}`;
  },
};

export const ADD_FAQ: Iroute = {
  path: 'add',
  get fullUrl(): string {
    return `${STATIC_CONTENT.fullUrl}/${this.path}`;
  },
};

export const EDIT_FAQ: Iroute = {
  path: 'edit',
  get fullUrl(): string {
    return `${STATIC_CONTENT.fullUrl}/${this.path}`;
  },
};

/**
 * @ABS === PROFILE
 */
export const PROFILE: Iroute = {
  path: 'profile',
  get fullUrl(): string {
    return `/${this.path}`;
  },
};
export const PROFILE_EDIT: Iroute = {
  path: 'edit',
  get fullUrl(): string {
    return `${PROFILE.fullUrl}/${this.path}`;
  },
};

export const PROFILE_DETAILS: Iroute = {
  path: 'detail',
  get fullUrl(): string {
    return `${PROFILE.fullUrl}/${this.path}`;
  },
};

export const CONTACT_US = {
  path: 'contact-us',
  get fullUrl(): string {
    return `${STATIC_CONTENT.fullUrl}/${this.path}`;
  },
}    

export const MASS_FIT_MANAGEMENT: Iroute = {
  path: 'massFit',
  get fullUrl(): string {
    return `/${this.path}`;
  },
};
export const ADD_POST: Iroute = {
  path: 'addPost',
  get fullUrl(): string {
    return `${MASS_FIT_MANAGEMENT.fullUrl}/${this.path}`;
  },
};
export const EDIT_POST: Iroute = {
  path: 'editPost',
  get fullUrl(): string {
    return `${MASS_FIT_MANAGEMENT.fullUrl}/${this.path}`;
  },
};
export const DETAIL_POST: Iroute = {
  path: 'detail',
  get fullUrl(): string {
    return `${MASS_FIT_MANAGEMENT.fullUrl}/${this.path}`;
  },
};
export const MARATHON_FIT_MANAGEMENT: Iroute = {
  path: 'marathon',
  get fullUrl(): string {
    return `/${this.path}`;
  },
};
export const ADD_POST_MARATHON: Iroute = {
  path: 'addPost',
  get fullUrl(): string {
    return `${MARATHON_FIT_MANAGEMENT.fullUrl}/${this.path}`;
},
}
export const EDIT_POST_MARATHON: Iroute = {
  path: 'editPost',
  get fullUrl(): string {
    return `${MARATHON_FIT_MANAGEMENT.fullUrl}/${this.path}`;
  },
};
export const DETAIL_POST_MARATHON: Iroute = {
  path: 'detail',
  get fullUrl(): string {
    return `${MARATHON_FIT_MANAGEMENT.fullUrl}/${this.path}`;
  },
};

export const CHANGE_VARIABLE_MANAGEMENT:Iroute= {
  path: 'change-variable',
  get fullUrl(): string {
    return `${this.path}`;
  },
}
export const VERSION_MANAGEMENT:Iroute= {
  path: 'version',
  get fullUrl(): string {
    return `${this.path}`;
  },
}
