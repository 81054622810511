export const API_STATUS = {
    SUCCESS: 200,
    TWO_ZERO_ONE: 201,
    TWO_ZERO_TWO: 202,
    FOUR_ZERO_ZERO: 400,
    FOUR_ZERO_ONE: 401,
    FOUR_ZERO_TWO: 402,
};

export const NUMBER_CONST = {
    ZERO: 0,
    ONE: 1,
    TWO: 2,
    THREE: 3,
    FOUR: 4,
    FIVE: 5,
    SIX: 6,
    SEVEN:7,
    THIRTY_TWO: 32
}