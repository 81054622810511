import { ABOUT_US, COMMUNITY_GUIDELINES, CONTACT_US, COPYRIGHT_POLICY, DATA_POLICY, FAQS, OTHERS, PRIVACY_POLICY, RETURN_POLICY, TERMS_CONDITIONS } from "./routes";

export const CMS_TAB_LINKS = [
  { path: PRIVACY_POLICY.path, label: "Privacy Policy" },
  { path: ABOUT_US.path, label: "About Us" },
  { path: FAQS.path, label: "FAQ's" },
  { path: TERMS_CONDITIONS.path, label: "Terms & Conditions" },
  { path: RETURN_POLICY.path, label: "Return Policy" },
  { path: CONTACT_US.path, label: "Contact Us" },
  { path: OTHERS.path, label: "Others" },
  // { path: DATA_POLICY.path, label: "Data Policy" },
];
export const CONTENT_TYPES = {
  PRIVACY_POLICY: "PRIVACY_POLICY",
  TERMS_CONDITIONS: "TERMS_AND_CONDITIONS",
  COPYRIGHT_POLICY: "COPYRIGHT_POLICY",
  COMMUNITY_GUIDELINES: "COMMUNITY_GUIDELINES",
  ABOUT_US: "ABOUT_US",
  DATA_POLICY: "DATA_POLICY",
  FAQS: "FAQ",
};

export const LANGUAGES_VALUE = {
  ARABIC: 'ar',
  ENGLISH: 'en'
}

export const LANGUAGES = [
  { name: 'Arabic', value: LANGUAGES_VALUE.ARABIC },
  { name: 'English', value: LANGUAGES_VALUE.ENGLISH },
]

export const THEME = {
  DARK:'dark',
  LIGHT : 'light'
}

export const ONBOARDING_KEYS = {
  ID:'id',
  TOKEN:'token',
  DEVICE_TOKEN:'deviceToken',
  DEVICE_ID:'deviceId',
  NEW_PASSWORD:'newPassword',
  ENCRYPTED_TOKEN:"encryptedToken",
  STRING: 'string'
}

export const STATIC_CONTENT_CONST = {
  DESCRIPTION_EN : 'descriptionEn',
  DESCRIPTION_AR : 'descriptionAr',
  QUESTION_AR:'questionAr',
  QUESTION_EN:'questionEn',
  ANSWER_AR:'answerAr',
  ANSWER_EN:'answerEn',
  CONTENT_TYPE:'contentType',
  CREATED : 'created',
  FAQS_ID:'faqId',
}

export const COMMON_KEYS = {
  LANGUAGE : 'language',
  CMS_PAGE:'cmsPage',
  THEME:'theme'
}

/**
 * HOTEL_RATINGS
 */
export const HOTEL_RATINGS = [
  {
    label: '1',
    value: '1',
    iconSrc: 'assets/images/icons/star.svg'
  },
  {
    label: '2',
    value: '2',
    iconSrc: 'assets/images/icons/star.svg'
  },
  {
    label: '3',
    value: '3',
    iconSrc: 'assets/images/icons/star.svg'
  },
  {
    label: '4',
    value: '4',
    iconSrc: 'assets/images/icons/star.svg'
  },
  {
    label: '5',
    value: '5',
    iconSrc: 'assets/images/icons/star.svg'
  }
];

/**
 * @HOTEL_BRANDS
 */
export const HOTEL_BRANDS = [
  {
    id: 1,
    label: 'Boudl',
    value: 'Boudl'
  },
  {
    id: 2,
    label: 'Aber',
    value: 'Aber'
  },
  {
    id: 3,
    label: 'Braira',
    value: 'Braira'
  },
  {
    id: 4,
    label: 'Narcissus',
    value: 'Narcissus'
  }
];
/**
 * @HOTEL_CITIES
 */
export const HOTEL_CITIES = [
  {
    id: 1,
    label: 'Riyadh',
    value: 'Riyadh'
  },
  {
    id: 2,
    label: 'Dammam',
    value: 'Dammam'
  },
  {
    id: 3,
    label: 'Wadi Al Dawasir',
    value: 'Wadi Al Dawasir'
  },
  {
    id: 4,
    label: 'Khamis Mushayt',
    value: 'Khamis Mushayt'
  },
  {
    id: 5,
    label: 'Al Khobar',
    value: 'Al Khobar'
  },
  {
    id: 6,
    label: 'Jeddah',
    value: 'Jeddah'
  },
  {
    id: 7,
    label: 'Abha',
    value: 'Abha'
  },
  {
    id: 8,
    label: 'Al-Qassim',
    value: 'Al-Qassim'
  },
  {
    id: 9,
    label: 'Al Jubail',
    value: 'Al Jubail'
  },
  {
    id: 10,
    label: 'Taif',
    value: 'Taif'
  },
  {
    id: 11,
    label: 'Kuwait',
    value: 'Kuwait City'
  },
  {
    id: 12,
    label: 'Hafar Al Batin',
    value: 'Hafar Al Batin'
  },
  {
    id: 13,
    label: 'Al Ahsa',
    value: 'Al Ahsa'
  },
  {
    id: 14,
    label: 'Jubail',
    value: 'Jubail'
  },
  {
    id: 15,
    label: 'Muhayil',
    value: 'Muhayil'
  },
  {
    id: 16,
    label: 'Fehihil',
    value: 'Fehihil'
  },
  {
    id: 17,
    label: 'Unayzah',
    value: 'Unayzah'
  },
  {
    id: 18,
    label: "Al Majma'ah",
    value: "Al Majma'ah"
  },
  {
    id: 19,
    label: 'Al Rass',
    value: 'Al Rass'
  },
  {
    id: 20,
    label: 'Buridah',
    value: 'BURIDAH'
  },
  {
    id: 21,
    label: 'Buraydah',
    value: 'Buraydah'
  },
  {
    id:22,
    label: "Khamis Mushait",
    value: "Khamis Mushait"
  }
];
/**
 * @STATUS_OPTIONS
 */
export const STATUS_OPTIONS = [
  {
    id: 1,
    label: 'Active',
    value: 'ACTIVE'
  },
  {
    id: 2,
    label: 'Blocked',
    value: 'BLOCKED'
  }
];
/**
 * @DASHBOARD_IMPORTANT_STATISTICS
 */
export const IMPORTANT_STATISTICS = [
  {
    url: "assets/images/svg/inactive-users.svg",
    title: "Inactive Users",
    data: 0,
  },
  {
    url: "assets/images/svg/active-users.svg",
    title: "Active Users",
    data: 0,
  },
];
export const CHALLENGES_STATISTICS = [
  {
    url: "assets/images/svg/self.svg",
    title: "Self",
    data: 0,
  },
  {
    url: "assets/images/svg/one-v-one.svg",
    title: "One-V-One",
    data: 0,
  },
  {
    url: "assets/images/svg/group.svg",
    title: "Group",
    data: 0,
  },
  {
    url: "assets/images/svg/pool.svg",
    title: "Pool",
    data: 0,
  }, 
  //  {
  //   url: "assets/images/svg/marathon.svg",
  //   title: "Marathon",
  //   data: 0,
  // },
];
export const PAYMENT_STATISTICS = [
  {
    url: "assets/images/svg/payin-completed.svg",
    title: "PayIn Completed",
    data: 0,
  },
  {
    url: "assets/images/svg/payin-failed.svg",
    title: "PayIn Failed",
    data: 0,
  },
  {
    url: "assets/images/svg/payout-completed.svg",
    title: "Payout Completed",
    data: 0,
  },
  {
    url: "assets/images/svg/payout-failed.svg",
    title: "Payout Failed",
    data: 0,
  },  {
    url: "assets/images/svg/payout-process.svg",
    title: "Payout Process",
    data: 0,
  },
];
export const GENDER_DROPDOWN=[
  {label:'Any',value:'Any'},
  {label:"Male",value:'Male'},
  {label:"Female",value:'Female'},
  // {label:"Other",value:'OTHER'},
]
export const REWARD_DISTRIBUTION = [
  {label:'Winner takes it all'},
  {label:'Relative distribution'}

]


export const BMI_DROPDOWN=[
  {label:'Any',value:'Any'},
  {label:"Underweight",value:'underweight'},
  {label:"Athlete",value:'athlete'},
  {label:"Normal",value:'normal'},
  {label:"Overweight",value:'overweight'},
  {label:"Obese",value:'obese'},
]
export const LEVEL_DROPDOWN =[
  {label:"Slower",value:'Fit'},
  {label:"Regular",value:'Fitter'},
  {label:"Faster",value:'Fittest'},
]

export const AGE_DROPDOWN =[
  {label:'Any',value:'Any'},
  {label:"18-25",value:'18-25'},
  {label:"26-40",value:'26-40'},
  {label:"41-50",value:'41-50'},
  {label:"50+",value:'Above50'},

]
export const checkEmail='paritoshd@gmail.com'
export const PLATFORM_DROPDOWN = [
  { value: "Android", label: "Android" },
  { value: "iOS", label: "iOS" },
];
export const STATUS_DROPDOWN = [
  { value: 0, label: "Active" },
  { value: 1, label: "Block" },
];
