import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StorageService } from '../../storage/storage.service';
import { NUMBER_CONST } from '../../../constants/number';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private _storage: StorageService) {}

  authorization(basic = false) {
    const token = this._storage.token;
    const auth: any = {
    'Authorization': token && !basic ?  token : 'Basic ' + btoa(`${'selfit'}:${'selfit@123'}`),
    // "Api_key": environment.API_KEY,
    deviceToken: 'dummy',
    deviceId: this._storage.deviceDetail(NUMBER_CONST.TWO),
    'platform': 'Web',
    'accept-language': "en",
    };
    return auth;
  }
}
