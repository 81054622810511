export const environment = {
  production: false,
  API_KEY: '1234',
  API_BASE_PATH: 'https://selfitapi.appskeeper.in/admin',
  s3: {
    AWS_ACCESS_KEY: '',
    AWS_SECRET_KEY: '',
    REGION: '',
    BUCKET_NAME: '',
    IMAGE_PREFIX: ''
  },
};
